import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';

import RadioGroup from 'components/uiLibrary/RadioGroup';
import Modal from 'components/uiLibrary/Modal';

import { ENTITY_TYPE, TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './CreateNewEntityDialog.module.scss';

const CreateNewArtist = dynamic(() => import('./CreateNewArtist'));
const CreateNewOrganization = dynamic(() => import('./CreateNewOrganization'));
const CreateNewAgency = dynamic(() => import('./CreateNewAgency'));

const ROLE_OPTIONS = [
  {
    label: `${TP}.FN_INDIVIDUAL_PERSON`,
    value: ENTITY_TYPE.ARTIST,
  },
  {
    label: `${TP}.FN_ORG`,
    value: ENTITY_TYPE.ORGANIZATION,
  },
  {
    label: `${TP}.M_AGENCY`,
    value: ENTITY_TYPE.MANAGER,
  },
];

const CreateNewEntityDialog = ({
  query: initialQuery,
  profession,
  organizationType,
  onSave,
  onClose,
  isOpen = true,
  allowedEntityTypes,
}) => {
  const [query, setQuery] = useState(initialQuery);
  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);

  const { t } = useTranslation('NS_APP_GLOBALS');
  const [contributionType, setContributionType] = useState(allowedEntityTypes?.[0]);

  const modalTitle = useMemo(() => {
    if (contributionType === ENTITY_TYPE.ORGANIZATION) {
      return t(`${TP}.FN_ADD_NEW_ORGANIZATION`);
    }

    if (contributionType === ENTITY_TYPE.ARTIST) {
      return t(`${TP}.FN_ADD_NEW_INDIVIDUAL_PERSON`);
    }

    if (contributionType === ENTITY_TYPE.MANAGER) {
      return t(`${TP}.FN_ADD_NEW_AGENCY`);
    }

    return t(`${TP}.AS_ADD_NEW`);
  }, [t, contributionType]);

  const options = useMemo(
    () =>
      ROLE_OPTIONS.reduce((acc, { label, value }) => {
        if (allowedEntityTypes?.includes(value)) {
          acc.push({ label: t(label), value });
        }
        return acc;
      }, []),
    [allowedEntityTypes, t],
  );

  if (!contributionType || options?.length === 0) {
    return null;
  }

  const onSaveHandler = value => {
    if (onSave) {
      onSave(value);
    }
  };

  return (
    <Modal
      title={modalTitle}
      styles={{
        modalContent: classes.modalContent,
        modalContainer: classes.modalContainer,
        drawerRoot: classes.drawerRoot,
        content: classes.drawerContent,
        drawerPaper: classes.drawerPaper,
      }}
      isOpen={isOpen}
      onClose={onClose}
      allowMobileDrawer
      disabledCloseHandler
    >
      {allowedEntityTypes?.length > 1 && (
        <>
          <div className={classes.title}>{t(`${TP}.PLEASE_SELECT_IDENTITY_TYPE`)}</div>
          <RadioGroup
            styles={{
              radioGroup: classes.radioGroup,
              radioWrapper: classes.radioWrapper,
              radioLabel: classes.radioLabel,
              radio: classes.radio,
            }}
            value={contributionType}
            options={options}
            onChange={setContributionType}
          />
        </>
      )}
      {contributionType === ENTITY_TYPE.ARTIST && (
        <CreateNewArtist query={query} profession={profession} onSave={onSaveHandler} />
      )}
      {contributionType === ENTITY_TYPE.ORGANIZATION && (
        <CreateNewOrganization query={query} onSave={onSaveHandler} defaultOrganizationType={organizationType} />
      )}
      {contributionType === ENTITY_TYPE.MANAGER && <CreateNewAgency query={query} onSave={onSaveHandler} />}
    </Modal>
  );
};

export default CreateNewEntityDialog;
