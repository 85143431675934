import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Popper } from '@mui/material';
import Loader from 'components/uiLibrary/Loader';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';
import classes from './AutocompleteDropDown.module.scss';

const AutocompleteDropDown = ({
  label,
  value,
  options,
  className,
  name,
  isLoading,
  onInputChange,
  onChange,
  renderMenuListItem,
  onOpen,
  disabled = false,
  clearOnBlur = false,
  size = 'small',
  fullWidth = false,
  disableClearable = false,
  trackingData = {},
  getOptionLabel,
  overrideOnInputChange = false,
  disablePortal = false,
  onBlur,
  InputLabelProps = {},
  InputProps = {},
  textFieldProps = {},
  ...props
}) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const getInputValue = useCallback(
    option => {
      if (getOptionLabel) {
        return getOptionLabel(option);
      }

      return option?.label || '';
    },

    // getOptionLabel is not a dependency because it should be set once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const [inputValue, setInputValue] = useState(getInputValue(value));

  // this use effect is to empty input field when value is empty if in case
  useEffect(() => {
    setInputValue(getInputValue(value));

    // getInputValue is not a dependency because it should be set once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onBlurHandler = useCallback(
    e => {
      if (clearOnBlur && e?.target?.value?.trim()?.length > 0) {
        setInputValue(getInputValue(value));
      }

      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    },
    // getInputValue is not a dependency because it should be set once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, clearOnBlur],
  );

  // in case if anyone wants to hide popper we can handle it here
  const popper = useCallback(
    params => {
      const { ...popperProps } = params;
      if (isLoading || options?.length === 0) {
        return null;
      }
      return <Popper {...popperProps} />;
    },
    [isLoading, options],
  );

  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        onBlur={onBlurHandler}
        label={label}
        variant="outlined"
        autoComplete="off"
        InputProps={{
          ...params.InputProps,
          style: {
            height: size === 'small' ? 42 : 56,
          },
          ...(inputValue?.length === 0 && { endAdornment: null }),
          ...(isLoading && {
            endAdornment: (
              <InputAdornment position="start">
                <Loader small color="#0067c5" />
              </InputAdornment>
            ),
          }),
          ...InputProps,
        }}
        InputLabelProps={{ ...InputLabelProps }}
        {...textFieldProps}
      />
    ),
    [isLoading, label, onBlurHandler, inputValue, InputLabelProps, InputProps, textFieldProps],
  );

  const handlerClickOption = useCallback(event => {
    event.stopPropagation();
  }, []);

  const renderOption = (innerProps, option, state) => {
    if (renderMenuListItem) {
      return renderMenuListItem(innerProps, option, state, trackingData);
    }

    return (
      <Box component="li" {...innerProps}>
        {(option?.label || getOptionLabel) && (
          <Typography>{getOptionLabel ? getOptionLabel(option) : option?.label}</Typography>
        )}
        {option?.noOption && !isLoading && state?.inputValue?.length > 0 && (
          <div
            onClick={handlerClickOption}
            onKeyDown={handlerClickOption}
            role="presentation"
            className={classes.noResult}
          >
            <SpriteIcon icon="search" size={30} className={classes.noResult__searchIcon} />
            <Typography weight="bold" size="16" className={classes.noResult__title}>
              {t(`${TP}.NO_RESULTS`)}
            </Typography>
            <Typography className={classes.noResult__info}>{t(`${TP}.ADJUST_SEARCH`)}</Typography>
          </div>
        )}
      </Box>
    );
  };

  const filterOptions = useCallback(fieldOptions => {
    if (!fieldOptions.length) {
      return [{ noOption: true, label: '' }];
    }
    return fieldOptions;
  }, []);

  const onInputChangeHandler = useCallback(
    (e, val, reason) => {
      setInputValue(val);
      if (typeof onInputChange === 'function') {
        onInputChange(e, val, reason);
      }
    },
    [onInputChange],
  );

  return (
    <Autocomplete
      className={classnames(classes.autocomplete, { [classes.fullWidth]: fullWidth, [className]: !!className })}
      key="autocomplete"
      name={name || 'autocomplete'}
      options={options}
      size={size}
      value={value || null}
      getOptionLabel={option => option?.label || ''}
      inputValue={inputValue || ''}
      PopperComponent={popper}
      renderInput={renderInput}
      onInputChange={overrideOnInputChange ? onInputChange : onInputChangeHandler}
      filterOptions={filterOptions}
      renderOption={renderOption}
      onChange={onChange}
      onOpen={onOpen}
      disabled={disabled}
      disableClearable={disableClearable}
      freeSolo
      disablePortal={disablePortal}
      {...props}
    />
  );
};

export default AutocompleteDropDown;
